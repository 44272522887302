/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export default function SideBar() {

    const user = JSON.parse(localStorage.getItem('user'));
    const details = user?.result
    //DATE TODAY
    var date = new Date();
    var year = date.getFullYear()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var today = moment(date).format("YYYY-MM-DD")
    var date1 = moment(firstDay).format("YYYY-MM-DD")
    var date2 = moment(lastDay).format("YYYY-MM-DD")
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    
    return (
        <div className="sidebar-mini">
            {/* Sidebar menu*/}
            <div className="app-sidebar__overlay" data-toggle="sidebar" />
            <aside className="app-sidebar toggle-sidebar " style={{ fontFamily: 'poppins' }}>

                <ul className="side-menu toggle-menu mt-2 ">
                    {details?.role_code === "sup" ?
                        <li className="slide ">
                            <Link className="side-menu__item fs-12" data-toggle="slide" to="/routings/validationTournee">
                                <span className="icon-menu-img">
                                    <i className=" fa fa-map-signs side_menu_img svg-1" />
                                </span>
                                <span className="side-menu__label">{t('rapports.validationTournee')}</span>
                            </Link>
                        </li> : null}
                    {details?.role_code === "chef" ?
                        <li className="slide ">
                            <Link className="side-menu__item fs-12" data-toggle="slide" to="/routings/TourneeZone">
                                <span className="icon-menu-img">
                                    <i className=" fa fa-map-signs side_menu_img svg-1" />
                                </span>
                                <span className="side-menu__label">{t('rapports.creationAgenda')}</span>
                            </Link>
                        </li> : null}
                    {details?.role_code === "agence" ?
                        <>
                            <li className="slide ">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/rapports/rapportSuiviPointage&datedebut=" + today + "&datefin=" + today + "&user_code=''"}>
                                    <span className="icon-menu-img">
                                        <i className="fa fa-podcast side_menu_img svg-1" />
                                    </span>
                                    <span className="side-menu__label">{t('rapports.RapportSuiviPointage')}</span>
                                </Link>
                            </li>
                            <li className="slide ">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to="/users/conge">
                                    <span className="icon-menu-img">
                                        <i className="icon icon-people side_menu_img svg-1" />
                                    </span>
                                    <span className="side-menu__label">Liste Congés</span>
                                </Link>
                            </li>
                        </>
                        : null}
                    {(details?.role_code === "admin" || details?.role_code === "chef_merch") ?
                        <>
                            <li className="slide ">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to="/">
                                    <span className="icon-menu-img">
                                        <i className="fa fa-tachometer side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label">{t('sidebar.tableaudebord')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                        

                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to="/rapports/">
                                    <span className="icon-menu-img">
                                        <i className="ti-agenda side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label"> {t('sidebar.Rapports')} </span>
                                </Link>
                            </li>
                            <Link className="side-menu__item fs-12" data-toggle="slide" to={"/commandes&date_debut="+date1+"&date_fin="+date2}>
                                    <span className="icon-menu-img">
                                        <i className="ti-package side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label"> Commandes</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" ti-layout side_menu_img svg-1" /></span>
                                    <span className="side-menu__label"> {t('Recap Suivi')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">

                                    <li><a href={"/suivi/suiviRelevePrix&datedebut="+date1+"&datefin="+date2} className="slide-item"> {t("Suivi Relevé Prix")} </a></li>
                                    <li><a href={"/suivi/suiviPL&datedebut="+date1+"&datefin="+date2} className="slide-item">{t('Suivi Part Linéaire')}</a></li>
                                    <li><a href={"/suivi/suiviRupture&datedebut="+date1+"&datefin="+date2} className="slide-item">{t('Suivi Rupture')}</a></li>

                                </ul>
                            </li>
                          


                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" icon icon-ban side_menu_img svg-1" /></span>
                                    <span className="side-menu__label">{t('sidebar.Ruptures')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href={"/ruptures/merch&datedebut=" + today + "&datefin=" + today+"&user_code='' "} className="slide-item">{t("Détails Rupture by Merch")} </a></li>
                                    <li><a href={"/ruptures/details&datedebut=" + today + "&datefin=" + today} className="slide-item">{t('sidebar.detailRuptures')} </a></li>
                                    <li><a href={"/ruptures/annuel&annee=" + moment(firstDay).format("YYYY")} className="slide-item">{t('sidebar.ruptureAnnuel')}</a></li>
                                </ul>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className="icon icon-grid side_menu_img svg-1 mb-2" /></span>
                                    <span className="side-menu__label">{t('dashboard.pl')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href={"/partlineaire/datedebut=" + date1 + "&datefin=" + date2} className="slide-item">{t('dashboard.pl')} </a></li>
                                    <li><a href={"/partlineaireAnnuel/" + year} className="slide-item">{t('Part linéaire Annuelle')}</a></li>
                                    <li><a href={"/partlineaireWeek/datedebut=" + date1 + "&datefin=" + date2} className="slide-item">{t("Part linéaire mensuelle")}</a></li>
                                </ul>
                            </li>
                            <li className="slide ">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/releve_prix/datedebut="+date1+"&datefin="+date2}>
                                    <span className="icon-menu-img">
                                        <i className="fa fa-tachometer side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label">{t('relevePrix.relevePrix')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" icon icon-people side_menu_img svg-1" /></span>
                                    <span className="side-menu__label">{t('sidebar.utilisateurs')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href="/users/liste" className="slide-item">{t('sidebar.listeUtilisateurs')} </a></li>
                                    <li><a href="/users/ajouterutilisateur" className="slide-item">{t('sidebar.ajoutUser')} </a></li>
                                    <li><a href="/users/parametrage" className="slide-item">{t('sidebar.parametrageUtilisateur')}</a></li>
                                    <li><a href="/users/conge" className="slide-item">{t('Liste Congé')}</a></li>

                                </ul>
                            </li>
                            <li className="slide ">
                             <Link className="side-menu__item fs-12" data-toggle="slide" to={"/autoEvaluation"}>
                                    <span className="icon-menu-img">
                                        <i className="ti-package side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label"> Auto Evaluation</span>
                            </Link>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" icon icon-docs side_menu_img svg-1" /></span>
                                    <span className="side-menu__label"> {t('Evaluation')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">

                                    <li><a href={"/QEvaluation"} className="slide-item"> {t("Questions Evaluation")} </a></li>
                                    <li><a href="/ListeEvaluation" className="slide-item">{t('Evaluation')}</a></li>
                                    <li><a href="/EvaluationAnnuelle" className="slide-item">{t('Evaluation Annuelle')}</a></li>

                                </ul>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className="ti-menu-alt side_menu_img svg-1 mb-2" /></span>
                                    <span className="side-menu__label">{t('sidebar.rayons')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href={"/rayons/datedebut=" + date1 + "&datefin=" + date2} className="slide-item">{t('sidebar.rayons')} </a></li>
                                    <li><a href={"/photorayons/datedebut=" + date1 + "&datefin=" + date2} className="slide-item">{t("Photo Rayons")} </a></li>
                                    <li><a href={"/chefrayons"} className="slide-item">{t("Chef Rayon")} </a></li>
                                </ul>
                            </li>

                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/checkUser/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img">
                                        <i className="icon icon-pin side_menu_img svg-1 mb-2" />
                                    </span> <span className="side-menu__label">{t('affectationtaches.affectationtaches')}</span>
                                </Link>

                            </li>
                            <li className="slide ">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className="ti-package side_menu_img svg-1" /></span>
                                    <span className="side-menu__label">{t('sidebar.produits')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href="/produits/liste" className="slide-item">{t('sidebar.listeProduits')}</a></li>
                                    <li><a href={"/produits/ajouterProduits"} className="slide-item">{t('Produits.AjouterProduits')} </a></li>
                                    <li><a href="/produits/Configurations" className="slide-item">{t('sidebar.configProduits')}</a></li>
                                    <li><a href="/produits/codeMETI" className="slide-item">{t('sidebar.configCodeMETI')}</a></li>
                                    <li><a href={"/produits/dlc&datedebut=" + date1 + "&datefin=" + date2} className="slide-item"> {t('sidebar.DLC')} </a></li>
                                    <li><a href="/produits/cadeaux" className="slide-item"> {t('sidebar.cadeaux')} </a></li>
                                    <li><a href="/produits/validationGratuite" className="slide-item"> {t('sidebar.validationGratuite')} </a></li>

                                </ul>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/promotion/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img">
                                        <i className="icon icon-tag side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label"> {t('dashboard.Promotions')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" fa fa-map-signs side_menu_img svg-1" /></span>
                                    <span className="side-menu__label">{t('routingsettournee')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">

                                    <li><a href="/routings/routings&route=-1&region=-1" className="slide-item">{t('rapports.creationTournee')}</a></li>
                                    <li><a href={"/routings/TourneeZone"} className="slide-item">{t('rapports.creationAgenda')} </a></li>
                                    <li><a href={"/routings/tournee&datedebut=" + date1 + "&datefin=" + date2} className="slide-item">{t('rapports.tournee')}</a></li>
                                    <li><a href="/routings/validationTournee" className="slide-item">{t('rapports.validationTournee')}</a></li>
                                    <li><a href="/routings/routes" className="slide-item">{t('rapports.routes')}</a></li>
                                    <li><a href="/routings/tournee" className="slide-item">{t('Tournee agenda par zone')}</a></li>

                                </ul>
                            </li>
                            <li className="slide">
                                <a className="side-menu__item fs-12" data-toggle="slide" href="#">
                                    <span className="icon-menu-img"><i className=" icon icon-home side_menu_img svg-1" /></span>
                                    <span className="side-menu__label">{t('rapports.Magasins')}</span><i className="angle fa fa-angle-right" /></a>
                                <ul className="slide-menu fs-12">
                                    <li><a href="/pdv/listePdv" className="slide-item">{t('sidebar.listeMagasins')} </a></li>
                                    <li><a href="/pdv/ajouterPDV" className="slide-item">{t('sidebar.creePDv')}</a></li>
                                    <li><a href="/pdv/configuration" className="slide-item">{t('sidebar.configurationPdv')}</a></li>
                                    <li><a href="/pdv/convention" className="slide-item">{t('sidebar.convention')}</a></li>
                                </ul>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to="/stockdepot">
                                    <span className="icon-menu-img fs-12">
                                        <i className="ti-server side_menu_img svg-1" />
                                    </span>
                                    <span className="side-menu__label">{t('rapports.stockDepot')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/messages/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img fs-12">
                                        <i className="icon icon-envelope side_menu_img svg-1 mb-2" />
                                    </span><span className="side-menu__label">{t("Messages")}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/notes/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img fs-12">
                                        <i className="icon icon-note side_menu_img svg-1 mb-2" />
                                    </span><span className="side-menu__label">{t('sidebar.notes')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/catalogue/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img fs-12" >
                                        <i className="icon icon-book-open side_menu_img svg-1 mb-2" />
                                    </span>
                                    <span className="side-menu__label">{t('sidebar.catalogue')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to="/zoning">
                                    <span className="icon-menu-img fs-12">
                                        <i className="ti-map-alt side_menu_img svg-1 mb-2" />
                                    </span><span className="side-menu__label">{t('sidebar.zoning')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/reclamation/datedebut=" + date1 + "&datefin=" + date2}>
                                    <span className="icon-menu-img fs-12">
                                        <i className="ti-alert side_menu_img svg-1 mb-2" />
                                    </span><span className="side-menu__label">{t('sidebar.reclammation')}</span>
                                </Link>
                            </li>
                            <li className="slide">
                                <Link className="side-menu__item fs-12" data-toggle="slide" to={"/documentation"}>
                                    <span className="icon-menu-img fs-12">
                                        <i className="ti-alert side_menu_img svg-1 mb-2" />
                                    </span><span className="side-menu__label">Documentation</span>
                                </Link>
                            </li>
                        </> : null}
                </ul>
            </aside>
        </div>
    )
}
