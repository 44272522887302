/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { patchObjectRoutings } from '../../../services/Routings/RoutingsApi'
import { SketchPicker } from 'react-color'
import { toast } from 'react-toastify'
import { getListeUsers } from '../../../services/Users/UsersApi'
import Loader from '../../../components/Loader/Loader'

export default function ModalEditRoute({
  keys,
  codeRoute,
  setShowEdit,
  object,t
}) {
  //---------- LISTE Routes -------------
  const [code, setCode] = useState('')
  const [color, setColor] = useState('')
  const [userCode, setUserCode] = useState('')
  const [listeUsers, setListeUsers] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setCode(object.code)
    setColor(object.color===null ? "": object.color)
    setUserCode(object.user_code)

    getListeUsers()
      .then((result) => {
        setListeUsers(result?.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
  }, [object])

  const saveData = () => {
    //Data of code & libelle FOR OBJECT PAYS
    if (code !== '' && color !== '') {
      const data = {
        color: color,
        user_code: userCode,
      }
      patchObjectRoutings(code, data)
        .then((result) => {
          if (result?.data?.success === 0) {
            toast(t('Zoning.Erreur'), {
              type: 'error',
              position: toast.POSITION.TOP_RIGHT,
            })
          } else if (result?.data?.success === 1) {
            setShowEdit(false)
            toast(t('messageErreur.modifsuccee'), {
              type: 'success',
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleChangeComplete = (color) => {
    setColor(color.hex)
  }
  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        key={keys}
      >
        <div className="modal-dialog" role="document">
          {loading ? (
            <Loader />
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="example-Modal3">
                {t('Routings.modifieruneroute')} : {codeRoute}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-control-label">{t('rapports.code')} :</label>
                        <input
                          type="text"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mr-2 mt-1 mb-sm-1">
                        {t('Produits.UTILISATEUR')} <span className="text-danger">*</span>
                        </label>
                        <select
                          name="item"
                          className="form-control  nice-select mb-0 nice-select-md col-12 "
                          value={userCode}
                          onChange={(e) => {
                            setUserCode(e.target.value)
                          }}
                        >
                          <option value={0}> {t('Routings.choisir')} </option>
                          {listeUsers?.map((items, keys) => (
                            <option value={items.code} key={keys}>
                              {items.code} : {items.nom}{' '}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-control-label">{t('Produits.Couleur')} :</label>
                    <SketchPicker
                      color={color}
                      onChangeComplete={handleChangeComplete}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary fs-12"
                  onClick={() => saveData()}
                >
                  <i className="ti-save mr-1" /> {t('Magasins.Enregistrer')}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary  fs-12"
                  data-dismiss="modal"
                >
                  <i className="ti-close mr-1" /> {t('Zoning.Fermer')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
