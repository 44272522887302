import React, { useEffect, useRef, useState } from 'react'
import { getSelctEnseignes } from '../../services/Produits/ProduitsAPI'
import { getMerch } from '../../services/Users/UsersApi'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { getSelectCategorie } from '../../services/Magasins/PdvApi';
import { toast } from 'react-toastify';
import { addTask } from '../../services/task/taskAPI';

export default function AddTask({ t ,setadd,add}) {

    //-------------- Formulaire ------------------
    const [date_debut, setdate_debut] = useState("")
    const [date_fin, setdate_fin] = useState("")
    const [codeUser, setcodeUser] = useState("")
    const [listeMerch, setlisteMerch] = useState([])
    const [listeEnsegines, setlisteEnseinges] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("-1")
    const [codeCategorie, setcodeCategorie] = useState("")
    const [listeCategorie, setlisteCategorie] = useState([])
    const [data, setData] = useState({
        rupture: 0, promotions: 0, releve_prix: 0, dlc: 0, rayon: 0, pl: 0
    })

    //----------------- REFERENCES -----------------
    const searchInput = useRef();

    // Handle Change enseigne
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
    };
    // Handle Change MERCHANDISEUR
    const handleChangeMerchandiseurs = (...args) => {
        setcodeUser(args[1].value)
    };
    // Handle Change Categorie
    const handleChangeCategorie = (...args) => {
        setcodeCategorie(args[1].value)
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.checked });
    }

    useEffect(() => {
        /**
         * Liste of Merchandisers
         */
        getMerch()
            .then((result) => {
                if (result?.data?.success === 1) {
                    var liste = result?.data?.data
                    liste.push({
                        value: "all",
                        name: "Tout les utilisateurs"
                    })
                    setlisteMerch(liste)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnseinges(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });

    }, [])

    const saveData = ()=>{
        if(codeUser==="") {
            toast("Veuillez sélectionner un merchandiseur", { type: 'warning', position: 'top-right' })
        }else if (codeEnseigne==="-1"){
            toast("Veuillez sélectionner une enseigne", { type: 'warning', position: 'top-right' })
        }else if (codeCategorie===""){
            toast("Veuillez sélectionner une catégorie", { type: 'warning', position: 'top-right' })
        }else if (date_debut>date_fin || date_debut==="" || date_fin===""){
            toast("Veuillez sélectionner une date valide", { type: 'warning', position: 'top-right' })
        }else {
            var el ={}
            Object.assign(el,{
                code:  parseInt(Date.now() * Math.random()),
                user_code : codeUser,
                enseigne_code :codeEnseigne,
                categorie_code : codeCategorie,
                date_debut: date_debut,
                date_fin : date_fin,
                rupture : data.rupture===true ? 1 :0,
                pl : data.pl===true ? 1 :0,
                dlc : data.dlc===true ? 1 :0,
                promotions : data.promotions===true ? 1 :0,
                releve_prix : data.releve_prix===true ? 1 :0,
                rayon : data.rayon===true ? 1 :0,
/*                 photorayon : data.photorayon===true ? 1 :0,
 */

            })
            addTask(el).then((result) => {
                if(result?.data?.success===1){
                    toast(t('messageErreur.ajoutavecsuccee'), { type: "success", position: 'top-right' })
                    setadd(!add)
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }

    return (

                <div className='row '>
                    <div className="col-12 mb-1" >
                        <label className="form-control-label fs-12">{t('dashboard.Merchandiseurs')} : <span className="text-danger"> * </span></label>
                        <SelectSearch
                            value={codeUser}
                            options={listeMerch}
                            search
                            ref={searchInput}
                            onChange={handleChangeMerchandiseurs}
                            filterOptions={fuzzySearch}
                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                            placeholder={t('affectationtaches.choisirmerchandiseur')}
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-control-label fs-12" >{t('Routings.datedebut')}</label>
                        <input type="date" value={date_debut} onChange={(e) => setdate_debut(e.target.value)} className="form-control fs-10 text-primary" />
                    </div>
                    <div className="col-6">
                        <label className="form-control-label fs-12" >{t('Produits.DATEFIN')}</label>
                        <input type="date" value={date_fin} onChange={(e) => setdate_fin(e.target.value)} className="form-control fs-10 text-primary" />
                    </div>
                    <div className="col-12 mt-2" >
                        <label className="form-control-label fs-12"> {t('Produits.Enseigne')}  : <span className="text-danger"> * </span></label>

                        <SelectSearch
                            value={codeEnseigne}
                            options={listeEnsegines}
                            search
                            ref={searchInput}
                            onChange={handleChangeEnseigne}
                            filterOptions={fuzzySearch}
                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                            placeholder={t('Magasins.Choisirenseigne')}
                        />

                    </div>
                    <div className="col-12 mt-2" >
                        <label className="form-control-label fs-12"> {t('rapportrupture.categorie')} : <span className="text-danger"> * </span> </label>
                        <SelectSearch
                            value={codeCategorie}
                            options={listeCategorie}
                            search
                            ref={searchInput}
                            onChange={handleChangeCategorie}
                            filterOptions={fuzzySearch}
                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                            placeholder={t('Magasins.ChoisirCategorie')}
                        />
                    </div>
                    <div className="col-12 mt-2" >
                        <div className="form-label fs-12">{t('affectationtaches.taches')}  : <span className="text-danger"> * </span> </div>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="rupture"
                            value={data.rupture}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className='fs-12'> {t('dashboard.rupture')} </span>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="promotions"
                            value={data.promo}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className='fs-12'> {t('relevePrix.promo')} </span>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="releve_prix"
                            value={data.releve}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className='fs-12'>{t('relevePrix.relevePrix')} </span>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="dlc"
                            value={data.dlc}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className='fs-12'> {t('sidebar.DLC')} </span>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="rayon"
                            value={data.rayon}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className='fs-12'> {t('rapports.rayon')} </span>
                    </div>
                    <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="pl"
                            value={data.pl}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className=' fs-12'> {t('dashboard.pl')} </span>
                    </div>
                   {/*  <div className="col-6 mt-1" >
                        <input
                            type="checkbox"
                            name="photorayon"
                            value={data.photorayon}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className=' fs-12'> Photo Rayon </span>
                    </div> */}
                    <div className='col-12 mt-3'>
                    <button className='btn btn-azure text-center fs-12 float-right' onClick={()=>saveData()}>
                        <i className='ti-save mr-1' /> {t('rapports.enregistrer')}
                    </button>
                    </div>
               
                </div>

            
    )
}
