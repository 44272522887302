import React from 'react'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

export default function ModalPartLineaire({ liste, t, loading ,listeHeaders}) {
  return (
    <div className="modal fade" id="exportMagasins" tabIndex={-1} role="dialog" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title text-azure fs-12" id="ListePDV"> {t('listepartlineaire')} </h5>
                <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close"  /> </span>
                
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="table-responsive  product-datatable"  style={{ overflow: 'auto', height: '500px' }}>
                   
                    <table  id="table-pl" className="table  table-bordered ">
                                <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }}>
                                
                                    <tr>
                                    <th className="w-15p fs-10"> {t('rapportrupture.date')} </th>
                                    <th className="wd-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.region')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.Enseignes')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.Magasin')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.Gamme')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.type')}</th>
                                    <th className="wd-15p fs-10">{t('dashboard.cible')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.facing')}</th>
                                    <th className="wd-15p fs-10">{t('rapports.facingtotal')}</th>
                                    <th className="wd-15p fs-10">{listeHeaders?.societe} (PL %)</th>
                                  {listeHeaders?.marque1 !== null && <th className="wd-15p fs-10">{listeHeaders?.marque1} (PL %)</th>} 
                                  {listeHeaders?.marque2 !== null && <th className="wd-15p fs-10">{listeHeaders?.marque2} (PL %)</th>} 
                                  {listeHeaders?.marque3 !== null && <th className="wd-15p fs-10">{listeHeaders?.marque3} (PL %)</th>}  
                                    </tr>
                                </thead>
                                {loading ? <SqaureLoader /> : (
                                    <tbody>
                                        {liste?.map((items, keys) => (
                                            <tr key={keys}  className={
                                                keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
                                            }>
                                                <td > {moment(items.date).format("DD/MM/YYYY")} </td>
                                               {/*    <td > {items.annee} </td>
                                                <td > {items.mois} </td>*/}
                                                <td > {items.merch} </td>
                                                <td > {items.region} </td>
                                                <td > {items.enseignes} </td>
                                                <td > {items.magasin} </td>
                                                <td > {items.gamme} </td>
                                                <td > {items.type} </td>
                                                <td > {items.cible} </td>
                                                <td > {items.facing} </td>
                                                <td > {items.facing_total} </td>
                                                 <td > {parseFloat((items.facing / items.facing_total) * 100).toFixed(2)} </td>
                                                 {listeHeaders?.marque1 !== null && <td > {parseFloat((items.plmarque1 / items.facing_total) * 100).toFixed(2)} </td>}
                                                 {listeHeaders?.marque2 !== null &&<td > {parseFloat((items.plmarque2 / items.facing_total) * 100).toFixed(2)} </td> }
                                                 {listeHeaders?.marque3 !== null &&<td > {parseFloat((items.plmarque3 / items.facing_total) * 100).toFixed(2)} </td>}

                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                  
                    </div>
                </div>
            </div>
         
            <div className="modal-footer ">
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-azure fs-10"
                    table="table-pl"
                    filename="liste-partLineaire"
                    sheet="tablexls"
                    buttonText={<span className=" text-center"> <i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')}</span>}
                />
                <button type="button" className="btn btn-secondary fs-10" data-dismiss="modal" > <i className="ti-close mr-1" />{t('Routings.annueler')}</button>
            </div>
        </div>
    </div>
</div>
  )
}
