/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import { getListeTask, updateTaskMerch } from '../../services/task/taskAPI'
import DeleteTask from './deleteTask'
import moment from 'moment'
export default function ListOfTask({ t, datedebut, datefin, add }) {
    //------ History -----------------
    const history = useHistory()
    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //--------- Search --------------------
    const [date_d, setdate_d] = useState(datedebut)
    const [date_f, setdate_f] = useState(datefin)
    const [codeUser, setCodeUser] = useState("")
    const [listeUsers, setlisteUsers] = useState([])
    const [listeTasks, setlisteTasks] = useState([])
    const [loading, setloading] = useState(true)
    const [index, setIndex] = useState(-1)
    const [ok, setok] = useState(false)
    //Modal items
    const [index2, setindex2] = useState(-1)
    const [code, setCode] = useState("")
    const [show2, setShow2] = useState(false)


    const onChangeValue = () => {
        if (date_d > date_f) {
            toast(t('messages.dateinf'), { type: 'warning', position: 'top-right' })
        } else {
            history.push({
                pathname:
                    '/checkUser/datedebut=' + date_d + '&datefin=' + date_f,
            })
            traitement()
        }
    }
    const onClickUser = () => {

    }
    const updateTask = (data, code) => {

        const el = {
            categorie_code: data.categorie_code,
            enseigne_code: data.enseigne_code,
            rupture: data.rupture === true || data.rupture === 1 ? 1 : 0,
            pl: data.pl === true || data.pl === 1 ? 1 : 0,
            dlc: data.dlc === true || data.dlc === 1 ? 1 : 0,
            promotions: data.promotions === true || data.promotions === 1 ? 1 : 0,
            releve_prix: data.releve_prix === true || data.releve_prix === 1 ? 1 : 0,
            rayon: data.rayon === true || data.rayon === 1 ? 1 : 0,
            photorayon : data.photorayon === true || data.photorayon === 1 ? 1 : 0,
            user_code: data.user_code
        }
        updateTaskMerch(code, el)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.modifsuccee'), { type: "success", position: 'top-right' })
                } else {
                    toast(t('messageErreur.erreurdemodification'), { type: "error", position: 'top-right' })

                }
            }).catch((err) => {
                console.log(err)
            });
    }

    const handleChange = (e, index) => {
        const clonedData = [...listeTasks]
        clonedData[index][e.target.name] = e.target.checked
        setlisteTasks(clonedData)
    }

    const traitement = () => {
        getListeTask(date_d, date_f)
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTasks(result?.data.data)
                    /*    const liste = {...result?.data.data}
                       const clonedData = [...liste]
                       result?.data?.data?.map((items,keys)=>{
                           clonedData[keys]["rupture"] = items.rupture==="1" || items.rupture==="true"? true : false
   
                       })
                       console.log(clonedData)
                       setlisteTasks(clonedData) */


                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }
    useEffect(() => {
        traitement()
    }, [add, ok, show2])

    return (
        <div className='card'>
            <div className='card-header mt-3 mb-0 pb-0'> <i className='icon icon-list mr-2 text-azure' /> {t('affectationtaches.listetaches')} </div>
            <div className='card-body'>
                <div className='row mb-2'>
                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className="input-group">
                            <input type="date" value={date_d} onChange={(e) => setdate_d(e.target.value)} className="form-control text-primary fs-12" />
                            <input type="date" value={date_f} onChange={(e) => setdate_f(e.target.value)} className="form-control text-primary fs-12" />
                            <span className="input-group-append">
                                <button className="btn btn-primary" type="button" onClick={() => onChangeValue()}><i className='ti-search' /></button>
                            </span>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12'></div>
                    {/*    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
                        <div className="tags mb-2  text-center" style={{ alignContent: "center" }}>
                            <button className="btn tag bg-azure text-white" onClick={() => onClickUser("all")}> {t('rapports.ALL')} </button>
                            {listeUsers?.map((items, keys) => (
                                <button className="btn tag bg-purple" key={keys} onClick={() => onClickUser(items.user_code)}> {items.nom} : ({items.total})</button>

                            ))}
                        </div>
                    </div> */}
                </div>
                <div className="table-responsive product-datatable mt-2" style={{ overflow: "scroll", height: "400px" }}>
                    <table
                        id="example"
                        className="table table-striped table-bordered "
                    >
                        <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                            <tr>
                                <th className="w-15p fs-10">{t('dashboard.Merchandiseurs')}</th>
                                <th className="w-15p fs-10">{t('dashboard.rupture')}</th>
                                <th className="w-15p fs-10">{t('rapports.rayon')}</th>
{/*                                 <th className="w-15p fs-10">Photo{t('rapports.rayon')}</th>
 */}                                <th className="wd-15p fs-10" >{t('relevePrix.relevePrix')}</th>
                                <th className="w-15p fs-10">{t('relevePrix.promo')}</th>
                                <th className="w-15p fs-10">{t('dashboard.pl')}</th>
                                <th className="w-15p fs-10">{t('sidebar.DLC')}</th>
                                <th className="w-15p fs-10">{t('dashboard.Enseigne')}</th>
                                <th className="w-15p fs-10">{t('dashboard.Enseigne')}</th>
                                <th className="w-15p fs-10">{t('Routings.datedebut')}</th>
                                <th className="w-15p fs-10">{t('Routings.datefin')}</th>
                                <th className="w-15p fs-10">{t('rapports.ACTION')}</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {loading ? <SqaureLoader /> :
                                listeTasks?.map((items, keys) => (
                                    <tr className='fs-10'>
                                        <td>{items.nom ===null ? "Tout les utilisateurs" : items.nom}</td>
                                        {ok === true && index === keys ? (
                                            <>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="rupture"
                                                        value={items.rupture}
                                                        checked={items.rupture}
                                                        onChange={(e) => handleChange(e, keys)}

                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="rayon"
                                                        value={items.rayon}
                                                        checked={items.rayon}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td>
                                              {/*   <td>
                                                    <input
                                                        type="checkbox"
                                                        name="photorayon"
                                                        value={items.photorayon}
                                                        checked={items.photorayon}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td> */}
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="releve_prix"
                                                        value={items.releve_prix}
                                                        checked={items.releve_prix}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="promotions"
                                                        value={items.promotions}
                                                        checked={items.promotions}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="pl"
                                                        value={items.pl}
                                                        checked={items.pl}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="dlc"
                                                        value={items.dlc}
                                                        checked={items.dlc}
                                                        onChange={(e) => handleChange(e, keys)}
                                                    />
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>
                                                    {items.rupture === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.rayon === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.photorayon === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.releve_prix === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.promotions === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.pl === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                                <td>
                                                    {items.dlc === 1 ? <i className='icon icon-check  text-success' /> : <i className='icon icon-close text-danger' />}
                                                </td>
                                            </>
                                        )}
                                        <td>
                                            {items.enseignes}
                                        </td>
                                        <td>
                                            {items.categorie}
                                        </td>
                                        <td>{moment(items.date_debut).format("DD/MM/YYYY")} </td>
                                        <td>{moment(items.date_fin).format("DD/MM/YYYY")} </td>

                                        <td>
                                            <i className='icon-note text-azure mr-1' onClick={() => { setok(!ok); setIndex(keys) }} />
                                            <i className='icon-trash text-danger' data-toggle="modal" data-target="#modal-notification" onClick={() => { setCode(items.code); setindex2(keys); setShow2(true) }} />
                                            {ok === true && index === keys ? (<i className='ti-save-alt text-primary mr-1 ml-1' onClick={() => { updateTask(listeTasks[keys], items.code); setok(!ok); }} />) : null}

                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
            </div>
            {show2 ? <DeleteTask setShow2={setShow2} index2={index2} code={code} t={t} /> : null}

        </div>
    )
}
