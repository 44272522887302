import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * GET LISTE COMMANDES 
 * @param {*} date_debut 
 * @param {*} date_fin 
 * @returns 
 */
 export async function getListeCommandes(date_debut,date_fin) {
    try {
        const response = await axios.get(url + "commandes/byDate/"+date_debut+"/"+date_fin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getListeCommandesByCode(commande_code) {
    try {
        const response = await axios.get(url + "commandes/byCode/"+commande_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getAllCMD(date_debut,date_fin) {
    try {
        const response = await axios.get(url + "commandes/all/"+date_debut+"/"+date_fin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * UPDATES LIST OF CMD
 * @param {*} LISTE 
 * @returns 
 */
export async function updateListeCommandes(LISTE) {
    try {
        const response = await axios.patch(url + "commandes/",LISTE, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Produits
 * @returns 
 */
export async function getListeProduits() {
    try {
        const response = await axios.get(url + "produits/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get ETAT PRODUIT
 * @returns 
 */
export async function getEtatProduit(code) {
    try {
        const response = await axios.get(url + "produits/etatProduit/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * UPDATE ETAT CONVENTION GMS
 * @param {*} code 
 * @param {*} etat 
 * @returns 
 */
export async function updateEtatGMS(code,etat) {
    try {
        const response = await axios.patch(url + "conventions/updateConvention/"+code,{etat:etat}, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : GRATUITE
 * @returns 
 */
 export async function getListeGratuite() {
    try {
        const response = await axios.get(url + "produits/gratuite", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : GRATUITE
 * @returns 
 */
 export async function getListeGratuiteSearch(user_code,date_debut,date_fin) {
    try {
        const response = await axios.get(url + "produits/searchgratuite/"+date_debut+"/"+date_fin+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Produits
 * @returns 
 */
export async function getListeConventions(code) {
    try {
        const response = await axios.get(url + "produits/convention/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * GET CATEGORIE ENSEIGNE
 * @param {*} code 
 * @returns 
 */
export async function getListeConventionEnseignes(code) {
    try {
        const response = await axios.get(url + "conventions/categorie_enseigne/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function filterProduit(codefamille, codemarque, codegammes, codesousfamille) {
    try {
        const response = await axios.get(url + "produits/famille_code=" + codefamille + "&marque_code=" + codemarque + "&gamme_code=" + codegammes + "&sousfamille_code=" + codesousfamille, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Add Produits with CSV File
 * @param {*} file 
 * @returns 
 */
export async function addProduitsCSV(file) {
    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "produits/add/csv" ,formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get liste  : Catalogue
 * @returns 
 */
export async function getListeDLC(limit, offset, datedebut, datefin) {
    try {
        const response = await axios.get(url + "produits/dlc/limit=" + limit + "&offset=" + offset + "/datedebut=" + datedebut + "&datefin=" + datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}
/**
 * Get SEARCH Liste  : DLC
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
export async function getSearchDLC(limit, offset, datedebut, datefin, data) {
    try {
        const response = await axios.post(url + "produits/dlcsearch/limit=" + limit + "&offset=" + offset + "/datedebut=" + datedebut + "&datefin=" + datefin, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}
/**
 * Get liste  : Produits
 * @returns 
 */
export async function getSelectProduits() {
    try {
        const response = await axios.get(url + "produits/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * Get SElect liste : ENseinges
 * @returns 
 */
export async function getSelctEnseignes() {
    try {
        const response = await axios.get(url + "enseignes/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}


/**
 * Get liste by code  : Produits
 * @returns 
 */
export async function getProduitsbyCode(code) {
    try {
        const response = await axios.get(url + "produits/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  : produits
 * @returns 
 */
export async function addProduits(data) {

    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("code", data.code);
    formData.append("libelle", data.libelle);
    formData.append("code_a_barre", data.code_a_barre);
    formData.append("actif", data.actif);
    formData.append("colisage", data.colisage);
    formData.append("unite", data.unite);
    formData.append("prix", data.prix);
    formData.append("prix_ht", data.prix_ht);
    formData.append("prix_achat", data.prix_achat);
    formData.append("marque_code", data.marque_code);
    formData.append("societe_code", data.societe_code);
    formData.append("gamme_code", data.gamme_code);
    formData.append("famille_code", data.famille_code);
    formData.append("sousfamille_code", data.sousfamille_code);

    try {
        const response = await axios.post(url + "produits/", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 *  Patch  UPDATE  : produits
 * @returns 
 */
export async function updateProduits(code, data) {
    const formData = new FormData();
    if (data.image !== undefined) {
        formData.append("image", data.image);
    }
    formData.append("code", data.code);
    formData.append("libelle", data.libelle);
    formData.append("code_a_barre", data.code_a_barre);
    formData.append("actif", data.actif);
    formData.append("colisage", data.colisage);
    formData.append("unite", data.unite);
    formData.append("prix", data.prix);
    formData.append("prix_ht", data.prix_ht);
    formData.append("prix_achat", data.prix_achat);
    if(data.marque_code !== null ){
        formData.append("marque_code", data.marque_code);
    }
    if(data.gamme_code !== null){
        formData.append("gamme_code", data.gamme_code);

    }
    if(data.famille_code !== null){
        formData.append("famille_code", data.famille_code);
    }
    if(data.sousfamille_code !== null){
        formData.append("sousfamille_code", data.sousfamille_code);
    }
    if(data.societe_code !== null){
        formData.append("societe_code", data.societe_code);
    }
    try {
        const response = await axios.patch(url + "produits/produit/" + code, formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * UPDATE ONLY ETAT
 * @param {*} code 
 * @param {*} etat 
 * @returns 
 */
export async function updateEtat (code,etat){
    const formData = new FormData();
    formData.append("actif",etat);
    try {
        const response = await axios.patch(url + "produits/produit/" + code, formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function updateCGMS (code,etat){
    const formData = new FormData();
    formData.append("actif",etat);
    try {
        const response = await axios.patch(url + "produits/produit/" + code, formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 *  Patch  UPDATE  : Gratutité
 * @returns 
 */
 export async function updateGratuite( code,data) {
   
    try {
        const response = await axios.patch(url + "produits/gratuite/"+code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 *  Patch  UPDATE  : Gratutité
 * @returns 
 */
 export async function addGratuite( data) {
   
    try {
        const response = await axios.put(url + "produits/addGratuite/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function deleteGratuites( code) {
   
    try {
        const response = await axios.delete(url + "produits/gratuite/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/*********************** CODE METI  ------------------------ */
/**
 * Get liste  : codemeti
 * @returns 
 */
export async function getListeCodeMETI() {
    try {
        const response = await axios.get(url + "codemeti/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * Get liste by code  : codemeti
 * @returns 
 */
export async function getCodeMetiByCode(id) {
    try {
        const response = await axios.get(url + "codemeti/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  : codemeti
 * @returns 
 */
export async function addCodeMeti(data) {
    try {
        const response = await axios.post(url + "codemeti/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
export async function addExcelCodeMeti(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "codemeti/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
export async function addExcelGammes(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "gammes/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
 export async function addExcelFamille(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "familles/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}

/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
 export async function addExcelSFamille(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "sousfamilles/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 * POST ADD  CODE METI : EXCEL
 * @returns 
 */
 export async function addExcelMarque(file) {


    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(url + "marques/excel", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 *  Patch  UPDATE  : codemeti
 * @returns 
 */
export async function updateCodeMeti(id, data) {
    try {
        const response = await axios.patch(url + "codemeti/" + id, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
/**
 *  Patch  UPDATE  : codemeti
 * @returns 
 */
export async function deleteCodeMETI(id) {
    try {
        const response = await axios.delete(url + "codemeti/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else if (error?.response?.status === 422) {
            return 422
        }
    }
}
export async function getListeRayons(datedebut, datefin) {
    try {
        const response = await axios.get(url + "rayons/datedebut=" + datedebut + "&datefin=" + datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}

export async function getImageByCode(code, type) {
    try {
        const response = await axios.get(url + `rayons/code=${code}&type=${type}`, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}


export async function getListeOfCategorieConventions(categorie_code, enseigne_code) {
    try {
        const response = await axios.get(url + `conventions/listeTypes/${categorie_code}/${enseigne_code}`, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else return error?.response?.status
    }
}