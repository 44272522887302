/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../../components/Loader/Loader'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import PaginationTable from '../../../components/Pagination/PaginationTable'
import { getListeSousFamilles, getSelectFamilles, getSousFamillebyCode } from '../../../services/Produits/configurationProduits/ConfigurationAPI'
import { SearchItems } from '../../../utils/SearchUtils'
import AddModalSF from './AddModalSF'
import EditModalSF from './EditModalSF'
import ModalExportSFamilles from './ModalExportSFamilles'
import ModalImportExcel from './ModalImportExcel'

export default function ListeSousFamille({ t }) {
    const [listeFamilles, setlisteFamilles] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [saveList, setSaveList] = useState([])
    const [selectfamille, setSelectFamilles] = useState([])

    //USE REF
    const inputEl = useRef("")
    //Search 
    const [search, setSearch] = useState('')
    //Loader 
    const [Loading, setLoading] = useState(true)
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(30);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeFamilles.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
    //Modal
    const [show, setShow] = useState(true)
    const [showEdit, setShowEdit] = useState(false)
    const [index, setIndex] = useState(-1)
    const [code, setCode] = useState("")
    const [object, setObject] = useState({})
    //MODAL  IMPROT EXCEL
    const [ok, setOk] = useState(false)
    useEffect(() => {
        setLoading(true)
        getSelectFamilles()
            .then((result) => {
                setSelectFamilles(result?.data?.data)
            }).catch((err) => {
                console.log(err)
            });
        getListeSousFamilles()
            .then((result) => {
                if (result?.data?.data !== null) {
                    setlisteFamilles(result?.data?.data)
                    setSaveList(result?.data.data)

                }
            }).catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])
    /**
    * Change Routes 
    */
    const changerDetailsFamilles = (code) => {
        setLoading(true)
        getSousFamillebyCode(code)
            .then((result) => {
                console.log(result.data.data[0])
                setObject(result.data.data[0])

            }).catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (search !== "") {
            const newList = SearchItems(listeFamilles, search)
            setSearchlist(newList)
        } else {
            setlisteFamilles(saveList)
        }
    }

    return (
        <div>
   
                <div className="row">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title mt-3 float-left"> <i className="icon icon-list mr-1 text-azure" /> {t('Produits.ListedesSousfamilles')} </div>
                            <button className="btn btn-azure float-right fs-12" data-toggle="modal" data-target="#addSFamille"> <i className="icon icon-plus mr-1" /> {t('Produits.Ajouter')}</button>
                            <button className='btn btn-green float-right mr-1 fs-12' data-toggle="modal" data-target="#excelSf" ><i className="fa fa-file-excel-o mr-1" /> {t('Produits.Importer')} </button>
                            <button className='btn btn-secondary float-right mr-1 fs-12' data-toggle="modal" data-target="#exportSF" ><i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')} </button>
                        </div>
                        <div className="card-body">
                            <div className="search1 search-elements-sm mb-4 ">
                                <div className="form row no-gutters">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <span className="input-group-append">
                                            <input
                                                type="text"
                                                ref={inputEl}
                                                value={search}
                                                onChange={() => getSearchTerm()}
                                                className="form-control br-tr-md-0 br-br-md-0 text-azure fs-12"
                                                id="text1"
                                                placeholder={t("Produits.Chercherunesfamille")}
                                            />
                                            <i className="text-azure ti-search ml-2 mt-4" />
                                        </span>
                                    </div>


                                </div>
                            </div>
                            {Loading ? (
                                <SqaureLoader />
                            ) : (
                                <>
                                    <div className="table-responsive product-datatable" style={{ overflow: "scroll", height: "400px" }}>
                                        <table
                                            id="example"
                                            className="table table-striped table-bordered "
                                        >
                                            <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                                                <tr>
                                                    <th className="w-15p fs-12">{t('rapports.code')}</th>
                                                    <th className="w-15p fs-12">{t('Produits.Libelle')}</th>
                                                    <th className="w-15p fs-12">{t('Produits.FAMILLE')}</th>
                                                    <th className="w-15p fs-12">{t('rapports.ACTION')}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center">
                                                {currentListe?.map((items, keys) => (
                                                    <tr key={keys} className={
                                                        keys % 2 === 1 ? "fs-12 bg-azure-lightest" : "fs-12"
                                                    }>
                                                        <td className='fs-10'>{items.code} </td>
                                                        <td className='fs-10'>{items.libelle} </td>
                                                        <td className='fs-10'>{items.famille_code} </td>
                                                        <td style={{ cursor: "pointer" }}>  <a data-toggle="modal" data-target="#editSF" onClick={() => {
                                                            changerDetailsFamilles(items.code); setCode(items.code); setIndex(keys); setShowEdit(true)
                                                        }}> <i className="icon icon-note text-azure" /></a> </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="float-right mt-3">
                                        <PaginationTable
                                            postsPerPage={stocksPerPage}
                                            totalPosts={search.length < 1 ? listeFamilles.length : searchList.length}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>  </>
                            )}
                        </div>
                    </div>
                </div>

           
            {showEdit ? <EditModalSF t={t} setShowEdit={setShowEdit} setShow={setShow} keys={index} object={object} listefamilles={selectfamille} /> : null}
            <AddModalSF t={t} listefamilles={selectfamille} setShow={setShow} />
            <ModalImportExcel setOk={setOk} t={t} />        
            <ModalExportSFamilles  listeFamilles={listeFamilles}   t={t}/>                                  
        </div>
    )
}
