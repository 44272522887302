import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SqaureLoader from '../../../components/Loader/SqaureLoader'
import { updateByCodeMarques } from '../../../services/Produits/configurationProduits/ConfigurationAPI'

export default function EditModalMarque({ keys, setShowEdit, setShow, object,t }) {
    //---- Formulaire --------------
    const [code, setCode] = useState("")
    const [libelle, setLibelle] = useState("")
    const [principale, setprincipale] = useState(0)
    const [actif, setactif] = useState(0)

    //---------- loader -----------
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setCode(object.code)
        setLibelle(object.libelle)
        setprincipale(object.principale)
        setactif(object.actif)

        setLoading(false)
    }, [object])

    /**
  * SAVE DATA
  */
    const saveData = () => {
        //Data of code & libelle FOR OBJECT PAYS
        if (code !== "" && libelle !== "") {
            const data = {
                code: code,
                libelle: libelle,
                principale : principale,
                actif : actif
            }
            updateByCodeMarques(code, data)
                .then((result) => {
                    console.log(result)
                    if (result?.data?.success === 0) {
                        toast(t('Zoning.Erreur'), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    } else if (result?.data?.success === 1) {
                        toast(t('messageErreur.modifsuccee'), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
    return (
        <div className="modal fade" id="modalMARQUE" tabIndex={-1} role="dialog" aria-hidden="true" key={keys}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalMARQUE">  <i className=' icon icon-equalizer mr-1 text-azure' /> Modifier détails Marque  </h5>
                        <span ><i className='icon icon-close text-azure' style={{ cursor: "pointer" }} data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} /> </span>

                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                           <form>
                           <div className='row'>
                               <div className="form-group col-6">
                                   <label className="form-control-label">{t('rapports.code')} :</label>
                                   <input type="text" disabled value={code} onChange={(e) => setCode(e.target.value)} placeholder={t('rapports.code')+" ..."} className="form-control" />
                               </div>
                               <div className="form-group col-6">
                                   <label className="mr-2 mt-1 mb-sm-1"> {t('Produits.Libelle')} <span className="text-danger">*</span></label>
                                   <input type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} placeholder= {t('Produits.Libelle')+" ... "} className="form-control" />
                               </div>
                           </div>
                           <div className='row'>
                               <div className="form-group col-6">
                                   <label className="form-control-label">{t('Produits.Principale')} :</label>
                                   <select className="form-control br-md-0" value={principale} onChange={(e) => setprincipale(e.target.value)}>
                                       <option value={1}>{t('rapports.oui')}</option>
                                       <option value={0}>{t('rapports.non')}</option>
                                   </select>
                               </div>
                               <div className="form-group col-6">
                                   <label className="mr-2 mt-1 mb-sm-1"> {t('Routings.actif')} <span className="text-danger">*</span></label>
                                   <select className="form-control br-md-0" value={actif} onChange={(e) => setactif(e.target.value)}>
                                       <option value={1}>{t('rapports.oui')}</option>
                                       <option value={0}>{t('rapports.non')}</option>
                                   </select>

                               </div>
                           </div>

                       </form>
                        )}
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-azure fs-12 "onClick={() => { saveData(); setShowEdit(true); setShow(false) }} ><i className='icon icon-cloud-download mr-1' /> {t('rapports.enregistrer')} </button>
                    <button type="button" className="btn btn-secondary fs-12" data-dismiss="modal"> <i className='ti-close mr-1'/> {t('Routings.annueler')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
