import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader'

dotenv.config()
const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code

/**
 * Get liste  : magasins
 * @returns 
 */
export async function getListeMagasins() {
    try {
        const response = await axios.get(url + "magasins/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * GET ETAT PDVS
 * @param {*} code 
 * @returns 
 */
export async function getEtatPDVS(code) {
    try {
        const response = await axios.get(url + "magasins/etat/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * GET ALL PDVS
 * @returns 
 */
export async function getAllListeMagasins() {
    try {
        const response = await axios.get(url + "magasins/all/pdvs", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function addMagasin(data) {
    try {
        const response = await axios.post(url + "magasins/add", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
 export async function addMultipleMagasin(file) {
    try {
        let formData = new FormData();
      formData.append("file", file);
        const response = await axios.post(url + "magasins/multiple", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
export async function updateMagasin(code, data) {
    try {
        const response = await axios.patch(url + "magasins/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
export async function getMagasinByCode(code) {
    try {
        const response = await axios.get(url + "magasins/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : grouped
 * @returns 
 */
export async function getListeMagasinsGrouped() {
    try {
        const response = await axios.get(url + "magasins/grouped", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : grouped
 * @returns 
 */
export async function getlisteEnseigne(code) {
    try {
        const response = await axios.get(url + "magasins/enseigne/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function getSelectCategorie() {
    try {
        const response = await axios.get(url + "magasins/categorie", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
 export async function getSelectMagasins() {
    try {
        const response = await axios.get(url + "magasins/select/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function getSelectRoute() {
    try {
        const response = await axios.get(url + "routings/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function getSelectRegions() {
    try {
        const response = await axios.get(url + "regions/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function getSelectGouvernorat() {
    try {
        const response = await axios.get(url + "gouvernorat/select", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function getSelectTypePdv() {
    try {
        const response = await axios.get(url + "magasins/typepdv", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}

/**
 * Get liste  : magasins
 * @returns 
 */
export async function getListeCategories() {
    try {
        const response = await axios.get(url + "categories/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function addCategorie(data) {
    try {
        const response = await axios.post(url + "categories/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function updateCategorie(code, data) {
    try {
        const response = await axios.patch(url + "categories/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}

/**
 * Get liste  : magasins
 * @returns 
 */
export async function getCategorieByCode(code) {
    try {
        const response = await axios.get(url + "categories/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}

/**
 * Get liste  : magasins
 * @returns 
 */
export async function getEnseigneByCode(code) {
    try {
        const response = await axios.get(url + "enseignes/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
/**
 * Get liste  : magasins
 * @returns 
 */
export async function addEnseignes(data) {
    try {
        const response = await axios.post(url + "enseignes/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}
export async function updateEnseigne(code, data) {
    try {
        const response = await axios.patch(url + "enseignes/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status

    }
}
export async function deleteEnseignes(code) {
    try {
        const response = await axios.delete(url + "enseignes/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status

    }
}
export async function listeEnseignes() {
    try {
        const response = await axios.get(url + "enseignes/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status

    }
}
/**  ------------- TYPE PDV  ------------------- */

/**
 * ADD TYPE PDV
 * @param {*} data 
 * @returns 
 */
export async function addTypePdv(data) {
    try {
        const response = await axios.post(url + "typePdv/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}


/**
 * UPDATE TYPE PDV
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
export async function updateTypePdv(code, data) {
    try {
        const response = await axios.patch(url + "typePdv/" + code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else
            return error.response.status

    }
}

/**
 * GET TYPE PDV by CODE
 * @param {*} code 
 * @returns 
 */
export async function getTypePdv(code) {
    try {
        const response = await axios.get(url + "typePdv/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}

/**
 * GET TYPE PDV by CODE
 * @param {*} code 
 * @returns 
 */
export async function getHistoriques(code,year) {
    try {
        const response = await axios.get(url + "magasins/historiques/"+code+"/"+year, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
/**
 * hISTORIQUE GAMME 
 * @param {*} code 
 * @param {*} year 
 * @returns 
 */
export async function ruptureGamme(code,year) {
    try {
        const response = await axios.get(url + "magasins/topGamme5/"+code+"/"+year, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}

/**
 * GET TYPE PDV by CODE
 * @param {*} code 
 * @returns 
 */
 export async function listeRuptures(month,year,pdv_code) {
    try {
        const response = await axios.get(url + "magasins/listeruptures/"+month+"/"+year+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
/**
 * GET TYPE PDV by CODE
 * @param {*} code 
 * @returns 
 */
 export async function historiqueAssortiments(year,categorie_code,enseigne_code) {
    try {
        const response = await axios.get(url + "magasins/historiqueassortiments/"+year+"/"+categorie_code+"/"+enseigne_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
export async function getHistoriquesImages(code,year) {
    try {
        const response = await axios.get(url + "magasins/historiquesImages/"+code+"/"+year, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
/**
 * get Assortiments
 * @param {*} code 
 * @param {*} mois 
 * @param {*} annee 
 * @returns 
 */
export async function getAssortiments(code, mois, annee) {
    //
    try {
        const response = await axios.get(url + "magasins/assortiments/code=" + code + "&mois=" + mois + "&annee=" + annee, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
/**
 * get Conventions
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
export async function getConventions() { 
    try {
        const response = await axios.get(url + "conventions/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}

/**
 * Delete Convention 
 * @param {*} code 
 * @returns 
 */
export async function deleteConventionGMS(code) {
    
    try {
        const response = await axios.delete(url + "conventions/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
export async function typeConvention() {
    //
    try {
        const response = await axios.get(url + "conventions/type", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
export async function getPromotionsConv() {
    //
    try {
        const response = await axios.get(url + "conventions/promotions", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
export async function searchConvention(data) {
    try {
        const response = await axios.post(url + "conventions/search",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}

export async function getConventionByCode (code) {
    try {
        const response = await axios.get(url + "conventions/" + code , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
/**
 * Add Conventions
 * @param {*} data 
 * @returns 
 */
export async function addConventions(data) {
    try {
        const response = await axios.post(url + "conventions/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
/**
 * Add Conventions
 * @param {*} data 
 * @returns 
 */
 export async function addConventionsProduits(data) {
    try {
        const response = await axios.post(url + "conventions/addConvProduit/", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
export async function updateConventions(code,data) {
    try {
        const response = await axios.patch(url + "conventions/"+code, data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
/**
 * Add Conventions
 * @param {*} data 
 * @returns 
 */
 export async function addFileConvention_1(data,file) {
     console.log(data)
    try {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("libelle", data.libelle);
        formData.append("date_debut", data.date_debut);
        formData.append("date_fin", data.date_fin);
        formData.append("type", data?.type);
        formData.append("enseigne_code", data?.enseigne_code);

        if(data?.type === "pl" ){
            formData.append("plcontrat", data.plcontrat);
            formData.append("implentation", data.implentation);
        }
        const response = await axios.post(url + "conventions/addfile", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }

}
/**
 * deleteConventions
 * @param {*} code 
 * @returns 
 */
export async function deleteConventions(code) {

    try {
        const response = await axios.delete(url + "conventions/" + code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        return error.response.status
    }
}
