/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import SqaureLoader from '../../../components/Loader/SqaureLoader';
import { getSelectCategorie, getSelectRegions, updateMagasin } from '../../../services/Magasins/PdvApi';
import { getPlanningMagasinbyPDV } from '../../../services/Rapports/RapportsApi';
import { getMerch } from '../../../services/Users/UsersApi';
import { toast, ToastContainer } from 'react-toastify';

export default function PlanningCategorie() {
    const { pdv_code, categorie_code } = useParams('')
    // REGIONS
    const { searchRegions, searchCategorie, searchUsers } = useRef();

    //---------- LISTE REGIONS --------------
    const [listeRegions, setlisteRegions] = useState([])
    const [listeCategories, setlisteCategories] = useState([])
    const [listeUsers, setlisteUsers] = useState([])
    //----------- INFROMATIONS TOURNEE --------------
    const [tournee, settournee] = useState([])
    const [infoPdv, setinfoPdv] = useState({})
    const [regionCode, setregionCode] = useState("")
    const [userCode, setUserCode] = useState("")
    const [categorieCode, setCategorieCode] = useState("")

    const [convgms, setconvgms] = useState([])
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    //-------- LOADER --------------------
    const [loading, setloading] = useState(true)


    useEffect(() => {
        //GET LISTE OF REGIONS 
        getSelectRegions().then((result) => {
            if (result?.data?.success === 1) {
                setlisteRegions(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        //GET LISTE OF CATEGORIE
        getSelectCategorie().then((result) => {
            if (result?.data?.success === 1) {
                setlisteCategories(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        //GET LISTE OF MERCHANDISEURS
        getMerch().then((result) => {
            if (result?.data?.success === 1) {
                setlisteUsers(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
        getPlanningMagasinbyPDV(categorie_code, pdv_code).then((result) => {
            if (result?.data?.success === 1) {
                console.log(result.data)
                settournee(result.data?.tournee)
                setinfoPdv(result.data?.informationPDV)
                setregionCode(result.data.informationPDV?.region_code)
                setUserCode(result.data.informationPDV?.user_code)
                setCategorieCode(result.data.informationPDV?.categoriepdv_code)
                setconvgms(result.data?.Conventions_GMS)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])

    const updatePDV = () => {
        const data = {}
        if(regionCode !== ""){
            Object.assign(data,{
                region_code : regionCode,
            })
        }
        if(userCode !== "" && userCode !== null){
            Object.assign(data,{
                user_code : userCode,
            })
        }
        if(categorieCode !== ""){
            Object.assign(data,{
                categoriepdv_code : categorieCode,
            })
        }
        console.log(data);
        /**
         * PDV_Code : code du magasin , data ={
         * region_code : regionCode,
         * user_code : userCode,
         * categorie_code : categorieCode
         * }
         */
        updateMagasin(pdv_code,data).then((result) => {
            if(result?.data?.success===1){
                toast(t("Routings.modifieSuccess"),{type:"success",position:'top-right'})
            }else {
                toast(t("Routings.erreur"),{type:"error",position:'top-right'})
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const handleChangeRegions = (...args) => {
        setregionCode(args[1].value)
    }
    const handleChangeCategorie = (...args) => {
        setCategorieCode(args[1].value)
    }
    const handleChangeUser = (...args) => {
        setUserCode(args[1].value)
    }
    return (
        <div> {/** --------------- HEADER ----------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')}  </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> Planning Magasin </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterMagasin')}
                        </a>
                        <a href="/pdv/convention" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-docs mr-1" />
                            {t('Magasins.Convention')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />

            <div className='row mt-2'>
                <div className='card'>
                    {loading ? <SqaureLoader /> :
                        <>
                            <div className='card-header'>
                                <h6 className='mt-3 text-center text-azure'> {t('Planning')} {infoPdv?.nompdv}</h6>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-1' />
                                    <div className='col-3'>
                                        <SelectSearch
                                            options={listeRegions}
                                            search
                                            ref={searchRegions}
                                            value={regionCode}
                                            onChange={handleChangeRegions}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t("Zoning.Listesdesregions")}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <SelectSearch
                                            options={listeCategories}
                                            search
                                            ref={searchCategorie}
                                            value={categorieCode}
                                            onChange={handleChangeCategorie}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t("Magasins.ListeCategorie")}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <SelectSearch
                                            options={listeUsers}
                                            search
                                            ref={searchUsers}
                                            value={userCode}
                                            onChange={handleChangeUser}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={t("sidebar.listeUtilisateurs")}
                                        />
                                    </div>
                                    <div className='col-1'>
                                        <button className='btn btn-azure text-white fs-12'  onClick={()=>updatePDV()}> <i className="ti-save mr-2  " />Modifier</button>
                                    </div>
                                    <div className='col-1' />

                                </div>
                                <div className='row '>
                                    <div className='col-xl-6  col-md-12 col-lg-6 col-12 mt-3'>
                                        <div className="table-responsive  product-datatable mt-3" style={{ overflow: 'auto', height: '500px' }}>
                                            <table className="table  table-bordered ">
                                                <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >

                                                    <tr>
                                                        <th className="wd-10p fs-10" >{t('rapports.code')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.CODEJOUR')}</th>
                                                        <th className="wd-15p fs-10" >{t('dashboard.Date')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.datedebut')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.datefin')}</th>
                                                        <th className="wd-15p fs-10" >{t('dashboard.actifs')}</th>

                                                    </tr>
                                                </thead>
                                                {loading ? <SqaureLoader /> : (
                                                    <tbody>
                                                        {tournee?.map((items, keys) => (
                                                            <tr key={keys} className="text-center fs-10">
                                                                <td>{items.code}</td>
                                                                <td>{items.code_jour}</td>
                                                                <td>{items.dates === null || items.dates === "" ? "-" : items.dates} </td>
                                                                <td>{moment(items.date_debut).format("DD/MM/YYYY")} </td>
                                                                <td>{moment(items.date_fin).format("DD/MM/YYYY")} </td>
                                                                <td>{items.actif === "1" ? <i className='ion-stop text-success' /> : <i className='ion-stop text-danger' />} </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}

                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-xl-6  col-md-12 col-lg-6 col-12 mt-3'>
                                        <div className="table-responsive  product-datatable mt-3" style={{ overflow: 'auto', height: '500px' }}>
                                            <table className="table  table-bordered ">
                                                <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                                    <tr>
                                                        <th className="w-15p fs-10" >{t('rapports.code')}</th>
                                                        <th className="wd-15p fs-10" >{t('Produits.CODEABARRE')}</th>
                                                        <th className="wd-15p fs-10" >{t('Produits.Produits')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.datedebut')}</th>
                                                        <th className="wd-15p fs-10" >{t('Routings.datefin')}</th>
                                                        <th className="wd-15p fs-10" >{t('dashboard.type')}</th>

                                                    </tr>
                                                </thead>
                                                {loading ? <SqaureLoader /> : (
                                                    <tbody>
                                                        {convgms?.map((items, keys) => (
                                                            <tr key={keys} className="text-center fs-10">
                                                                <td>{items.code}</td>
                                                                <td>{items.code_a_barre}</td>
                                                                <td>{items.produit} </td>
                                                                <td>{moment(items.date_debut).format("DD/MM/YYYY")} </td>
                                                                <td>{moment(items.date_fin).format("DD/MM/YYYY")} </td>
                                                                <td>{items.type} </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}

                </div>
            </div>
        </div>
    )
}
