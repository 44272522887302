/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import { toast, ToastContainer } from 'react-toastify'
import { getMagasinByCode, getSelectCategorie, getSelectGouvernorat, getSelectRegions, getSelectRoute, getSelectTypePdv, updateMagasin } from '../../../services/Magasins/PdvApi'
import { getSelctEnseignes } from '../../../services/Produits/ProduitsAPI'
import { getSelectLocalite, getSelectZone } from '../../../services/Zoning/ZoningApi'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export default function ModifierPDV() {
    const { code } = useParams('')
    //-------------------- FORMULAIRE -------------------
    const [codeErp, setcodeErp] = useState("")
    const [nom, setnom] = useState("")
    const [adresse, setadresse] = useState("")
    const [adresseLivraison, setadresseLivraison] = useState("")
    const [description, setDescription] = useState("")
    const [codeTypeMagasin, setcodeTypeMagasin] = useState("")
    const [codeCategorie, setcodeCategorie] = useState("")
    const [codeEnseigne, setcodeEnseigne] = useState("")
    const [codeRoute, setcodeRoute] = useState("")
    const [codeCentralise, setcodeCentralise] = useState("")
    const [codeRegion, setcodeRegion] = useState("")
    const [codeZone, setcodeZone] = useState("")
    const [codeGouvernorat, setcodeGouvernorat] = useState("")
    const [groupedPDV, setgroupedPDV] = useState([])
    const [groupedRegion, setgroupedRegion] = useState([])
    const [groupedZone, setgroupedZone] = useState([])
    const [groupedGouvernorat, setgroupedGouvernorat] = useState([])
    const [groupedRoute, setgroupedRoute] = useState([])
    const [codeLocalite, setcodeLocalite] = useState("")
    const [groupedLocalite, setgroupedLocalite] = useState([])
    const [groupedCategorie, setgroupedCategorie] = useState([])
    const [groupedEnseigne, setgroupedEnseigne] = useState([])
    const [actif, setactif] = useState(1)
    const [logitude, setlogitude] = useState("10.127410")
    const [latitude, setlatitude] = useState("36.857129")
    //---------------- LISTE --------------------------
    const [listeEnsegines, setlisteEnsegines] = useState([])
    const [listeCategorie, setlisteCategorie] = useState([])
    const [listeTypePdv, setlisteTypePdv] = useState([])
    const [listeRoute, setlisteRoute] = useState([])
    const [listeRegion, setlisteRegion] = useState([])
    const [listeZones, setlisteZones] = useState([])
    const [listeGouvernorat, setlisteGouvernorat] = useState([])
    const [listeLocalite, setlisteLocalite] = useState([])
    //----------------- REFERENCES -----------------
    const searchInput = useRef();
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        getMagasinByCode(code)
            .then((result) => {
                if (result?.data?.success === 1) {
                    const data = result?.data?.data[0]
                    setcodeErp(data?.erp)
                    setnom(data?.nompdv)
                    setadresse(data?.adresse)
                    setadresseLivraison(data?.adresse_livraison)
                    setDescription(data?.description)
                    setcodeTypeMagasin(data?.typepdv_code=== null? "" :data?.typepdv_code)
                    setcodeCategorie(data?.categoriepdv_code)
                    setcodeEnseigne(data?.enseigne_code)
                    setcodeRoute(data?.routing_code)
                    setcodeCentralise(data?.centralise === null ? 0 : data?.centralise)
                    setcodeRegion(data?.region_code  === null ? "" :data?.region_code )
                    setcodeZone(data?.zone_code  === null ? "" :data?.zone_code)
                    setcodeGouvernorat(data?.gouvernorat_code === null ? "" :data?.gouvernorat_code)
                    setcodeLocalite(data?.localite_code=== null ? "" :data?.localite_code)
                    setactif(data?.isactif)
                    setlogitude(data?.logitude)
                    setlatitude(data?.latitude)


                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectGouvernorat()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteGouvernorat(result?.data?.data)
                    var grouped = _.groupBy(result?.data?.data, "value")
                    setgroupedGouvernorat(grouped)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectLocalite().then((result) => {
            if (result?.data?.success === 1) {
                setlisteLocalite(result?.data?.data)
                var grouped = _.groupBy(result?.data?.data, "value")
                setgroupedLocalite(grouped)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectZone().then((result) => {
            if (result?.data?.success === 1) {
                setlisteZones(result?.data?.data)
                var grouped = _.groupBy(result?.data?.data, "value")
                setgroupedZone(grouped)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectRegions()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteRegion(result?.data?.data)
                    var grouped = _.groupBy(result?.data?.data, "value")
                    setgroupedRegion(grouped)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectRoute().then((result) => {
            if (result?.data?.success === 1) {
                setlisteRoute(result?.data?.data)
                var grouped = _.groupBy(result?.data?.data, "value")
                setgroupedRoute(grouped)
            }
        }).catch((err) => {
            console.log(err)
        });
        getSelectCategorie()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteCategorie(result?.data?.data)
                    var grouped = _.groupBy(result?.data?.data, "value")
                    setgroupedCategorie(grouped)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelectTypePdv()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteTypePdv(result?.data?.data)
                    var grouped = _.groupBy(result?.data?.data, "value")
                    setgroupedPDV(grouped)
                }
            }).catch((err) => {
                console.log(err)
            });
        getSelctEnseignes()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setlisteEnsegines(result?.data?.data)
                    var grouped = _.groupBy(result?.data?.data, "value")
                    setgroupedEnseigne(grouped)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])
    // Handle Change enseigne
    const handleChangeEnseigne = (...args) => {
        setcodeEnseigne(args[1].value)
    };
    // Handle Change Categorie
    const handleChangeCategorie = (...args) => {
        setcodeCategorie(args[1].value)
    };
    // Handle Change type pdv
    const handleChangeTypePdv = (...args) => {
        setcodeTypeMagasin(args[1].value)
    };
    // Handle Change route
    const handleChangeRoute = (...args) => {
        setcodeRoute(args[1].value)
    };
    // Handle Change route
    const handleChangeRegions = (...args) => {
        setcodeRegion(args[1].value)
    };
    // Handle Change route
    const handleChangeZone = (...args) => {
        setcodeZone(args[1].value)
    };
    // Handle Change route
    const handleChangeGouvernorat = (...args) => {
        setcodeGouvernorat(args[1].value)
    };
    // Handle Change route
    const handleChangeLocalite = (...args) => {
        setcodeLocalite(args[1].value)
    };

    const saveData = () => {
        const data = {
            erp: codeErp,
            isactif: actif,
            nompdv: nom,
            logitude: logitude,
            latitude: latitude,
            adresse: adresse,
            adresse_livraison: adresseLivraison,
            description: description,
            typepdv_code: codeTypeMagasin,
            categoriepdv_code: codeCategorie,
            enseigne_code: codeEnseigne,
            centralise: codeCentralise,
        }
        if(codeRoute !== "" && codeRoute!== null){
            Object.assign(data, {
                routing_code: codeRoute,
            })
        }
        if (codeRegion !== "") {
            Object.assign(data, {
                region_code: codeRegion,
            })
        }
        if (codeZone !== "") {
            Object.assign(data, {
                zone_code: codeZone,
            })
        }
        if (codeLocalite !== "") {
            Object.assign(data, {
                localite_code: codeLocalite,
            })
        }
        updateMagasin(code, data)
            .then((result) => {
                if (result?.data?.success === 1) {
                    toast(t('messageErreur.modifsuccee'), { type: "success", position: "top-right" })
                } else {
                    toast(t('messageErreur.erreurdemodification'), { type: "error", position: "top-right" })
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    return (
        <div>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')} </a></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Magasins.modifiermagasin')}: {code} </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterPDV')}
                        </a>
                        <a href="/pdv/convention" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-12">
                            <i className="icon icon-docs mr-1" />
                            {t('Magasins.Convention')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                {/* ------------------ COORDONEES MAGASINS --------------------- */}
                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <h5 className="card-title">  {t('Magasins.CoordonneesMagasin')}   </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('rapports.code')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={code} readOnly disabled className="form-control" placeholder="Code pdv ... " />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.CodeERP')}: </label>

                                        <input type="text" value={codeErp} onChange={(e) => setcodeErp(e.target.value)} className="form-control" placeholder="Code ERP ... " />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Nommagasins')} : <span className="text-danger"> * </span></label>

                                        <input type="text" value={nom} required onChange={(e) => setnom(e.target.value)} className="form-control" placeholder="Nom magasin ... " />
                                    </div>

                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Adresse')}  </label>

                                        <input type="text" value={adresse} onChange={(e) => setadresse(e.target.value)} className="form-control" placeholder={t('Magasins.Adresse')} />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Adressedelivraison')} </label>

                                        <input type="text" value={adresseLivraison} onChange={(e) => setadresseLivraison(e.target.value)} className="form-control" placeholder={t('Magasins.Adressedelivraison')} />
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                {/* ------------------ TYPE MAGASINS --------------------- */}
                <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <h5 className="card-title"> {t('Magasins.DetailsMagasins')} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.TypeMagasin')}: </label>
                                        <SelectSearch
                                            value={codeTypeMagasin}
                                            options={listeTypePdv}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeTypePdv}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeTypeMagasin !== "" ? (groupedPDV[codeTypeMagasin] !== undefined ? groupedPDV[codeTypeMagasin][0]?.name : t('Magasins.ChoisirTypeMagasin')) : t('Magasins.ChoisirTypeMagasin')}
                                        />

                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('rapportrupture.categorie')} : </label>
                                        <SelectSearch
                                            value={codeCategorie}
                                            options={listeCategorie}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeCategorie}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeCategorie !== "" ? (groupedCategorie[codeCategorie] !== undefined ? groupedCategorie[codeCategorie][0]?.name : t('Magasins.ChoisirCategorie')) : t('Magasins.ChoisirCategorie')}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Produits.Enseigne')}  :</label>
                                        <SelectSearch
                                            value={codeEnseigne}
                                            options={listeEnsegines}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeEnseigne}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeEnseigne !== "" ? (groupedEnseigne[codeEnseigne] !== undefined ? groupedEnseigne[codeEnseigne][0]?.name : t('Magasins.Choisirenseigne')) : t('Magasins.Choisirenseigne')}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Route')}   </label>
                                        <SelectSearch
                                            value={codeRoute}
                                            options={listeRoute}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeRoute}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeRoute !== "" ? (groupedRoute[codeRoute] !== undefined ? groupedRoute[codeRoute][0]?.name : t('Magasins.Choisirroute')) : t('Magasins.Choisirroute')}
                                        />
                                    </div>

                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Centralise')}  </label>

                                        <select className="form-control br-md-0" value={codeCentralise} onChange={(e) => setcodeCentralise(e.target.value)}>
                                            <option value={0}>{t('Magasins.Non')} </option>
                                            <option value={1}>{t('Magasins.Oui')} </option>

                                        </select>
                                    </div>

                                </div>
                                <div className="col-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Actif')} </label>

                                        <select className="form-control br-md-0" value={actif} onChange={(e) => setactif(e.target.value)}>
                                            <option value={0}>{t('Magasins.Non')} </option>
                                            <option value={1}>{t('Magasins.Oui')} </option>

                                        </select>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* ------------------ Localisation --------------------- */}
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header mt-3 mb-0 pb-0">
                            <h5 className="card-title"> {t('Magasins.Localisation')} </h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Magasins.Region')} : <span className="text-danger"> * </span></label>
                                        <SelectSearch
                                            value={codeRegion}
                                            options={listeRegion}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeRegions}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeRegion !== "" ? (groupedRegion[codeRegion] !== undefined ? groupedRegion[codeRegion][0]?.name : t('Magasins.Choisirregion')) : t('Magasins.Choisirregion')}

                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Zone')}: </label>
                                        <SelectSearch
                                            value={codeZone}
                                            options={listeZones}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeZone}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeZone !== "" ? (groupedZone[codeZone] !== undefined ? groupedZone[codeZone][0]?.name : t('Magasins.Choisirzone')) : t('Magasins.Choisirzone')}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Gouvernorat')} : <span className="text-danger"> * </span></label>

                                        <SelectSearch
                                            value={codeGouvernorat}
                                            options={listeGouvernorat}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeGouvernorat}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeGouvernorat !== "" ? (groupedGouvernorat[codeGouvernorat] !== undefined ? groupedGouvernorat[codeGouvernorat][0]?.name : t('Magasins.ChoisirGouvernorat')) : t('Magasins.ChoisirGouvernorat')}
                                        />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Localite')} : <span className="text-danger"> * </span></label>
                                        <SelectSearch
                                            value={codeLocalite}
                                            options={listeLocalite}
                                            search
                                            ref={searchInput}
                                            onChange={handleChangeLocalite}
                                            filterOptions={fuzzySearch}
                                            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{t('Zoning.Aucunresultat')}</div>}
                                            placeholder={codeLocalite !== "" ? (groupedLocalite[codeLocalite] !== undefined ? groupedLocalite[codeLocalite][0]?.name : t('Magasins.ChoisirLocalite')) : t('Magasins.ChoisirLocalite')}

                                        />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Longitude')}  </label>

                                        <input type="text" value={logitude} onChange={(e) => setlogitude(e.target.value)} className="form-control" />
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-control-label"> {t('Magasins.Latitude')} </label>

                                        <input type="text" value={latitude} onChange={(e) => setlatitude(e.target.value)} className="form-control" />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-control-label">{t('Magasins.Description')} </label>

                                        <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <button className="btn btn-primary float-right" onClick={() => saveData()} ><i className="ti-save mr-1" /> {t('Magasins.Enregistrer')} </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
